<template>
  <div>
    <p class="mb-0">{{ field.label }}</p>
    <div v-if="field.search">
      <flat-pickr
        v-if="field.search.type === 'date'"
        v-model="dateValue"
        class="form-control form-control-sm rounded-sm custom-padding custom-date-picker-field"
        :placeholder="`Search ${field.label}...`"
        :config="dateFormat"
        style="min-width: 175px"
        @input="(value) => onInputDate(value)"
        @focus.native="() => setFocusedField(field.key)"
        @click.native="() => setFocusedField(field.key)"
      />
      <cleave
        v-else-if="field.search.type === 'number'"
        v-model.number="field.search.value"
        class="form-control form-control-sm text-right rounded-sm custom-padding"
        :placeholder="`Search ${field.label}...`"
        :options="numeric"
        style="min-width: 150px"
        @input="() => searchData(field.key)"
        @focus.native="() => setFocusedField(field.key)"
        @click.native="() => setFocusedField(field.key)"
      />
      <v-select
        v-else-if="field.search.type === 'select'"
        class="select-size-sm rounded-sm custom-padding custom-select-field"
        :reduced="item => item[getSelectInputId]"
        :options="options"
        :input-id="getSelectInputId"
        :label="getSelectLabel"
        :loading="isLoading"
        :clearable="field.search.clearable !== undefined ? field.search.clearable : true"
        :placeholder="`Search ${field.label}...`"
        :filterable="field.search
          ? (field.search.server_side === false || (field.search.options || []).length > 0)
          : false"
        style="min-width: 150px"
        :value="options.find(option => option[getSelectInputId] === field.search.value)"
        @input="setSelectValue"
        @focus.native="() => setFocusedField(field.key)"
        @click.native="() => setFocusedField(field.key)"
        @search="(value) => getSelectData(value)"
      ></v-select>
      <b-form-input
        v-else
        v-model="field.search.value"
        type="text"
        size="sm"
        class="rounded-sm custom-padding"
        :placeholder="`Search ${field.label}...`"
        style="min-width: 150px"
        @input="() => searchData(field.key)"
        @focus="() => setFocusedField(field.key)"
        @click="() => setFocusedField(field.key)"
      />
    </div>
  </div>
</template>


<script>
import flatPickr from "vue-flatpickr-component"
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import { BFormInput } from 'bootstrap-vue'
import moment from 'moment';
import useHttp from '@/comp-functions/useHttp'
import { ref } from '@vue/composition-api'


export default {
  components: {
    flatPickr,
    BFormInput,
    Cleave,
    vSelect
  },


  emits: [
    "set-focused-field",
    "search-data"
  ],


  props: {
    field: Object,
    fields: Array,
    focusedField: String
  },


  data () {
    return {
      dateFormat: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        mode: 'range',
        onClose: (value) => {
          let tempValue = ''
          tempValue = value[0] ? moment(value[0]).format('YYYY-MM-DD') : ''
          tempValue += value[1] ? ` - ${moment(value[1]).format('YYYY-MM-DD')}` : ''
          this.field.search.value = `${tempValue}`
          this.dateValue = `${this.field.search.value}`

          this.searchData(this.field.key)
        }
      },
      numeric: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      selectedLabel: '',
      dateValue: '',
      fetchTimer: null
    }
  },


  setup (props) {
    const { $get } = useHttp()
    const options = ref([])
    const isLoading = ref(false)
    const searchTimer = ref(null);

    const getSelectData = async (search = '') => {
      const searchValue = search.trim()

      if (props.field.search?.server_side === false) {
        if (options.value.length < 1) {
          oneTimeGetSelectData()
        }

        return null
      }

      if (props.field.search.options) {
        options.value = !searchValue ? props.field.search.options
          : props.field.search.options.filter(item =>
            `${item.label || item.id || item || ''}`.toLowerCase().includes(searchValue.toLowerCase())
          )
        return null
      }

      if (searchValue.length < 2) {
        options.value = []
        return null
      }

      isLoading.value = true
      clearTimeout(searchTimer.value)

      searchTimer.value = setTimeout(async () => {
        try {
          const url = props.field.search.url.includes('?')
            ? props.field.search.url
            : `${props.field.search.url}?`
          const response = await $get({ url: `${url}&limit=50&search=${search}` })
          options.value = response.data || []
        } catch (error) {
          options.value = []
        } finally {
          isLoading.value = false
        }
      }, 500)
    }

    const oneTimeGetSelectData = async (search = '') => {
      isLoading.value = true
      try {
        const response = await $get({ url: `${props.field.search.url}?limit=50&search=${search}` })
        options.value = response.data || []
      } catch (error) {
        options.value = []
      } finally {
        isLoading.value = false
      }
    }

    return {
      getSelectData,
      options,
      isLoading
    }
  },


  computed: {
    tableColumns () {
      return this.fields
    },

    focused () {
      return this.focusedField
    },

    getSelectInputId () {
      return this.field.search ? (this.field.search.id || 'id') : 'id'
    },

    getSelectLabel () {
      return this.field.search ? (this.field.search.label || 'label') : 'label'
    }
  },


  async mounted () {
    await this.checkSearchType()
    this.options = (this.field.search?.options || []).map(opt => {
      return { id: opt.id ?? opt, label: opt.label ?? opt }
    })

    if (this.field.search?.server_side === false) {
      await this.getSelectData()
    }

    // Initialize select field with default value if provided
    if (this.field.search?.type === 'select' && this.field.search?.value) {
      // Find the option that matches the default value
      const defaultOption = this.options.find(option => 
        option[this.getSelectInputId] === this.field.search.value
      )
      
      if (defaultOption) {
        // Set the selected value
        this.$nextTick(() => {
          this.setFocusedField(this.field.key)
          this.setSelectValue(defaultOption)
        })
      }
    }
    // For non-select fields with default values, trigger search
    else if (this.field.search?.value && this.field.search?.type !== 'select') {
      this.$nextTick(() => {
        this.setFocusedField(this.field.key)
        this.searchData(this.field.key)
      })
    }
  },


  methods: {
    checkSearchType () {
      const acceptedTypes = [
        'text',
        'date',
        'select',
        'number'
      ]

      this.tableColumns.map(field => {
        if (field.search) {
          const value = `${typeof field.search.value}` === 'string' ? (field.search.value || '') : (field.search.value || 0)
          const type = field.search.type || ''
          let message = `Field '${field.key}'`

          if (!type) {
            message += ` didn't defined a search type`
          } else if (!acceptedTypes.includes(type)) {
            message += ` with type '${type}' is not accepted`
          } else if (type === 'number' && `${typeof value}` !== 'number') {
            message = `the value of ${message} with type '${type}', must be numeric`
          } else {
            if (type === 'select' && value) {
              this.searchData(field.key)
            }
            return null
          }

          console.warn(`${message}, it will considered as 'text' type.\n(accepted types: ${acceptedTypes.join(', ')})`)
        }
      })
    },

    setFocusedField (fieldKey) {
      return this.$emit('set-focused-field', fieldKey)
    },

    setSelectValue (item) {
      this.field.search.value = item ? item[this.getSelectInputId] : ''
      this.selectedLabel = item ? item[this.getSelectLabel] : ''
      this.searchData(this.field.key)
    },

    onInputDate (value) {
      if (!value) {
        this.field.search.value = value
        this.searchData(this.field.key)
      }
    },

    async searchData (_focusedField = "") {
      try {
        let queryParams = ""
        // Use the provided _focusedField if available, otherwise use this.focused
        const focusedKey = _focusedField || this.focused
        const fieldIndex = await this.tableColumns.findIndex(field => field.key === focusedKey)

        if (fieldIndex < 0) {
          if (!['select', 'number'].includes(this.field.search.type)) {
            throw new Error(`Error when finding Focused field: '${focusedKey}'`)
          } else {
            return null
          }
        }

        const focusedValue = this.tableColumns[fieldIndex].search.value || ""
        const focusedSearchType = this.tableColumns[fieldIndex].search.type
        const focusedMinLength = this.tableColumns[fieldIndex].search.min_length

        await Promise.all(this.tableColumns.map(field => {
          if (field.search && field.search.value) {
            let value = null
            const fieldType = field.search.type

            if (fieldType === 'number' && `${typeof field.search.value}` === 'number') {
              value = parseInt(`${field.search.value || 0}`)
            } else {
              value = `${(field.search.value || '')}`.trim()
            }

            queryParams += `&${field.search.key || field.key}=${value}`
          }
        }))

        clearTimeout(this.fetchTimer)
        const self = this
        this.fetchTimer = setTimeout(function () {
          if (focusedSearchType) {
            if (focusedSearchType === 'text') {
              if (focusedMinLength) {
                if (focusedValue.length === 0 || focusedValue.length >= focusedMinLength) {
                  return self.$emit('search-data', queryParams)
                }
              } else {
                if (focusedValue.length === 0 || focusedValue.length >= 2) {
                  return self.$emit('search-data', queryParams)
                }
              }
            } else {
              return self.$emit('search-data', queryParams)
            }
          }
        }, 500)

      } catch (error) {
        console.error(error.message || error)
      }
    }
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.custom-padding {
  padding: 0 4px;
}

.custom-date-picker-field {
  min-width: 90px;
}

.custom-select-field {
  min-width: 150px;
  background-color: white;
  padding: 0;
}

.custom-select-field .vs__search {
  padding: 0;
}

.custom-select-field .vs__search::placeholder {
  color: #bbbbbb !important;
  font-size: 12px;
}

.custom-select-field .vs__spinner {
  font-size: 3px;
}

.custom-select-field .vs__open-indicator {
  display: none;
}

.custom-select-field .vs__actions {
  padding-right: 0;
}
</style>
