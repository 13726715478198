import { ref, computed, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
// import { da } from 'date-fns/locale'
// import { da } from 'date-fns/locale'

/**
 * Logic for fetching list into the table
 * Will use in many component, be careful when want to change this function
 * @param {object} payload
 * @param {string} payload.url
 * @param {boolean} payload.disableManualInit
 */
export default function useListTablePage (payload) {
  const { $get }            = useHttp()
  const isBusy              = ref(false)
  const refListTable        = ref(null)
  const perPage             = ref(15)
  const perPageDefault      = ref(15)
  const itemLists           = ref([])
  const totalList           = ref(0)
  const currentPage         = ref(1)
  const currentPage2        = ref(0)
  const perPageOptions      = [10, 25, 50, 100]
  const searchQuery         = ref('')
  const customSearchQuery   = ref('')
  const sortBy              = ref('code')
  const isSortDirDesc       = ref(true)
  const statusFilter        = ref(null)
  const totalCounts         = ref(0)
  const focusedSearchField = ref('')


  const dataMeta =  computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0

    if (currentPage2.value != currentPage.value) {
      currentPage2.value = currentPage.value
      fetchLists()
    }

    return {
      from: (perPage.value * (currentPage.value - 1)) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalList.value
    }
  })


  // fetch data to api endpoint
  const fetchLists = async (url = payload.url, urlcount = payload.urlcount) => {
    if (payload.urlcount) {
      await $get({
        url: urlcount
      })
        .then((value) => {
          totalCounts.value = value.count || 0
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    if (url) {
      const safeUrl = url.includes('?') ? url : `${url}?`
      let data = []
      let count = 0
      let total = 0
      let per_page = perPageDefault.value

      isBusy.value = true
      await $get({
        url: `${safeUrl}&limit=${perPageDefault.value}${!payload.urlcount ? `&page=${currentPage.value}&` : ''}${customSearchQuery.value}`
      })
        .then((result) => {
          data = result.data || []
          count = result.count || null
          total = result.total || null
          per_page = result.per_page || perPageDefault.value
        })
        .finally(() => {
          isBusy.value = false
        })

      try {
        const dataCount = !payload.urlcount ? (count || total || data.length || 0) : totalCounts.value
        const perPageValue = !payload.urlcount ? (per_page || perPageDefault.value) : currentPage.value
        itemLists.value = data
        totalList.value = dataCount
        perPage.value = (dataCount < perPageDefault.value ? dataCount : perPageValue)
      } catch (e) {
        console.log(`Error when set item list after fetch: ${e}`)
      }
    } else {
      console.warn("No URL Provided")
    }
    
    return 1
  }
  
  // Alias for fetchLists for backward compatibility
  const loadPage = fetchLists

  const changePerPage = async (page, forDefault = true) => {
    if (forDefault) {
      perPageDefault.value = page
      perPage.value = page
      await fetchLists()
    } else {
      perPage.value = page
    }
  }

  const searchData = async (queryParams) => {
    customSearchQuery.value = queryParams
    await fetchLists()
  }

  onMounted(async () => {
    if (!payload.disableManualInit) {
      await fetchLists()
    }
  })

  return {
    isBusy,
    itemLists,
    perPage,
    currentPage,
    totalList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    statusFilter,
    fetchLists,
    changePerPage,
    loadPage,
    customSearchQuery,
    focusedSearchField,
    searchData
  }
}
